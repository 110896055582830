import Cookies from '../../../../../assets/vendor/js-cookie/dist/js.cookie.mjs';

export default class SegmentSwitcher{
    constructor($el) {
        this.$segmentBar = $el;
        this.$segmentBarContent = $el.querySelector('[data-segment-top-bar-content]');
        this.$currenSegmentLabel = $el.querySelector('[data-current-segment]');
        this.$oldSegmentLabel = $el.querySelector('[data-old-segment]');
        this.$backToOldSegmentButton = $el.querySelector('[data-back-to-old-segment]');
        this.$removeCookieButton = $el.querySelector('[data-remove-segment-cookie]');

        this.backToOldSegment = this.backToOldSegment.bind(this);
        this.removeActiveSegmentCookie = this.removeActiveSegmentCookie.bind(this);
        this.calculateTopBarHeight = this.calculateTopBarHeight.bind(this);

        this.$backToOldSegmentButton.addEventListener('click', this.backToOldSegment);
        this.$removeCookieButton.addEventListener('click', this.removeActiveSegmentCookie);
        window.addEventListener('resize', this.calculateTopBarHeight);

        if (! this.getSegmentCookie()) {
            this.setActiveSegmentInCookie();
        }

        if (this.getSegmentCookie() !== this.getCurrentSegment()) {
            const currentSegmentLabel = this.getSegmentLabel(this.getCurrentSegment());
            this.setCurrentSegmentLabel(currentSegmentLabel);

            const oldSegmentLabel = this.getSegmentLabel(this.getSegmentCookie());
            this.setOldSegmentLabel(oldSegmentLabel);

            setTimeout(() => this.showSegmentBar(true), 700);
        }

        this.calculateTopBarHeight();
    }

    /**
     * Get current segment
     * @return {*}
     */
    getCurrentSegment() {
        return placeholders.router.locale;
    }

    /**
     * Get Segment from cookie
     * @return {*}
     */
    getSegmentCookie() {
        return Cookies.get('segment');
    }

    /**
     * Set active segment in cookie
     */
    setActiveSegmentInCookie() {
        Cookies.set('segment', this.getCurrentSegment());
    }

    /**
     * Remove active segment cookie
     * @param e
     */
    removeActiveSegmentCookie(e) {
        e.preventDefault();
        Cookies.remove('segment');
        this.showSegmentBar(false);
    }

    /**
     * Seg current segment label
     * @param label
     */
    setCurrentSegmentLabel(label) {
        this.$currenSegmentLabel.innerHTML = label;
    }

    /**
     * Set old segment label
     * @param label
     */
    setOldSegmentLabel(label) {
        this.$oldSegmentLabel.innerHTML = label;
    }

    /**
     * Get segment label
     * @param segment
     * @return {*}
     */
    getSegmentLabel(segment) {
        return segmentLabels[segment];
    }

    /**
     * Show segment bar
     * @param show
     */
    showSegmentBar(show) {
        const $body = document.querySelector('body')
        this.$segmentBar.classList.toggle('top-bar--visible', show);

        document.querySelector('body').classList.toggle('top-bar-visible', show);
    }

    /**
     * Handle click set segment active
     * @param e
     */
    backToOldSegment(e) {
        e.preventDefault();
        window.location = segmentLinks[this.getSegmentCookie()];
    }

    /**
     * Calculate the top bar height
     */
    calculateTopBarHeight() {
        document.querySelector('body').style.setProperty('--top-bar-height', this.$segmentBarContent.offsetHeight + 'px');
    }
}