// Local
import "./ui-kit";
import CtcSlider from "./partials/ctc-slider";
import CharacterList from "./partials/character-list";
import Gallery from "./partials/gallery";
import AjaxList from "./partials/ajax-list";
import Videoplayer from "./partials/videoplayer";
import Newsletter from "./partials/newsletter";
import StickyContainer from "./partials/sticky-container";
import InlineCollapsable from "./partials/inline-collapsable";
import QuoteSlider from "./partials/quote-slider";
import MPower from "./partials/m-power";
import BackButton from "./partials/back-button";
import HeroSlider from "./partials/hero-slider";
import LinkTo from "./partials/link-to";
import SegmentSwitcher from "./partials/segment-switcher";

window.initDom = ($wrapper) => {
    $wrapper.querySelectorAll('[data-block-type="ctc-list"]').forEach($el => (new CtcSlider($el)));
    $wrapper.querySelectorAll('[data-block-type="brand-character-list"]').forEach($el => (new CharacterList($el)));
    $wrapper.querySelectorAll('[data-block-type="gallery"]').forEach($el => (new Gallery($el)));
    $wrapper.querySelectorAll('[data-block-type="fun-list"]').forEach($el => (new AjaxList($el)));
    $wrapper.querySelectorAll('[data-block-type="article-list"]').forEach($el => (new AjaxList($el)));
    $wrapper.querySelectorAll('[data-block-type="vacancy-list"]').forEach($el => (new AjaxList($el)));
    $wrapper.querySelectorAll('[data-videoplayer]').forEach($el => (new Videoplayer($el)));
    $wrapper.querySelectorAll('[data-newsletter-form]').forEach($el => (new Newsletter($el)));
    $wrapper.querySelectorAll('[data-sticky-container]').forEach($el => (new StickyContainer($el)));
    $wrapper.querySelectorAll('.collapsable-trigger').forEach($element => (new InlineCollapsable($element)));
    $wrapper.querySelectorAll('[data-block-type="quote-list"]').forEach($el => (new QuoteSlider($el)));
    $wrapper.querySelectorAll('[data-block-type="m-power-block"]').forEach($el => (new MPower($el)));
    $wrapper.querySelectorAll('[data-block-type="music-album-list"]').forEach($el => (new AjaxList($el)));
    $wrapper.querySelectorAll('[data-back]').forEach($el => (new BackButton($el)));
    $wrapper.querySelectorAll('[data-hero-slider]').forEach($el => (new HeroSlider($el)));
    $wrapper.querySelectorAll('[data-href]').forEach($el => (new LinkTo($el)));
    $wrapper.querySelectorAll('[data-segment-top-bar]').forEach($el => (new SegmentSwitcher($el)));

    /**
     * Wrap videos in reading in video container
     */
    $wrapper.querySelectorAll('.reading iframe[src*="youtube.com"], .reading iframe[data-cmp-src*="youtube.com"]').forEach(($el) => {
        if (!$el.parentElement.classList.contains('.video-container') && !$el.parentElement.classList.contains('.embed-container')) {
            let wrapper = document.createElement('div');
            wrapper.classList.add('video-container')
            $el.parentNode.insertBefore(wrapper, $el);
            wrapper.appendChild($el);
        }
    });
}

(function() {
    window.initDom(document);
})();